import React, { useState } from 'react'
import Layout from '../components/Layout'

import { Link } from 'gatsby'
import { SubscriptionForm } from '../components/SubscriptionForm'
import SEO from '../components/seo'
import { Post } from '../components/Post'
import { graphql } from 'gatsby'
import { red, grey, purple, green } from '@material-ui/core/colors'
import { Divider, Grid, Typography, Button, Paper } from '@material-ui/core'

const PostsPreview = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      const { title } = node.frontmatter
      const { slug } = node.fields || {}
      return (
        <Typography key={title} gutterBottom color={'textSecondary'}>
          {title}
        </Typography>
      )
    })}
  </>
)
const PostsWithImages = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      return (
        <Post
          slug={node.fields.slug}
          title={node.frontmatter.title || node.fields.slug}
          cover={node.frontmatter.cover}
        />
      )
    })}
  </>
)

const mapArrToPosts = arr =>
  arr.map(title => ({
    node: {
      frontmatter: {
        title,
      },
    },
  }))

const MY_BENEFITS = [
  'Make me value time more',
  'Improve Health',
  'Improve productivity',
  'Improve Focus',
  'Allowed to build great habats',
  'Reduce my anxity',
  'Structure my day',
]

const STEPS_ABOUT_POMODORO = [
  {
    title: 'Choose a task',
  },
  {
    title: 'Start a timer (e.g. 25 m).',
  },
  {
    title: 'Start a work session — work on your task',
  },
  {
    title: 'When time is out put a checkmark on a paper',
  },
  {
    title: 'Take a short break. Every 4 work sessions take a longer break',
  },
]

const PomodoroExplanation = () => (
  <>
    <div>
      <div style={{ padding: '20px', border: '1px solid grey' }}>
        <Typography variant={'headline'}>
          The Pomodoro Technique is an approach to time management.
        </Typography>
      </div>
      <br />
      <div style={{ padding: '6px 20px' }}>
        <Typography variant={'overline'}>Core process is:</Typography>
      </div>
      <Paper>
        {STEPS_ABOUT_POMODORO.map((step, index) => (
          <div
            key={step.title}
            style={{
              padding: '20px 20px 0px 20px',
              backgroundColor: index % 2 === 0 ? purple[700] : green[700],
            }}
          >
            <Typography variant={'overline'} color={'textSecondary'}>
              Step #{index + 1}
            </Typography>
            <Typography variant={'headline'} gutterBottom>
              {step.title}
            </Typography>
            <br />
          </div>
        ))}
      </Paper>
    </div>
  </>
)

const Princinples = () => {
  return (
    <Grid container>
      {['Time Boxing', 'Task Awareness', 'Recovery Management'].map(title => (
        <Grid item md={4}>
          <Typography
            variant={'h6'}
            align={'center'}
            style={{ padding: '40px 20%' }}
          >
            {' '}
            {title}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default props => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
      allMdx: { edges },
    },
    location,
  } = props

  const [showDescription, setShowDescription] = useState(false)
  return (
    <>
      <Layout location={location} title={title}>
        <SEO
          title={'Pomodoro Technique'}
          keywords={[`pomodoro`, `technique`, `personal experiecen`]}
        />
        <Typography variant={'h6'} color={'secondary'}>
          TOPIC
        </Typography>
        <Typography variant={'h3'} color={'secondary'}>
          The Pomodoro Technique
        </Typography>

        <Typography variant={'headline'} color={'secondary'}>
          How I've Teach Myself To Be Productive 1000 Times While Building
          Habits I Am Happy About
        </Typography>
        <br />
        <Typography variant={'overline'}>OVERVIEW</Typography>

        <Typography variant={'body1'}>
          Pomodoro Technique helps me to manage health, productivity and gave me
          an opportunity to build great habits. At first I failed at it hardly.
          Now it gives me huge benefits.
        </Typography>
        <br />

        <Button
          size={'large'}
          onClick={() => setShowDescription(!showDescription)}
          variant={'outlined'}
        >
          What is the Pomodoro Technique?
        </Button>
        <br />
        <br />
        {!!showDescription ? (
          <>
            <PomodoroExplanation />
            <br />
          </>
        ) : null}

        <Typography variant={'overline'}>SUBSCRIBE</Typography>
        <SubscriptionForm />
        <br />
        <Typography variant={'overline'}>POSTS</Typography>
        <PostsWithImages
          posts={edges.filter(post => {
            return (
              post.node.frontmatter.projects &&
              post.node.frontmatter.projects.includes('pomodoro')
            )
          })}
        />
        <Divider />
        <br />
        <Typography variant={'overline'}>COMING LATER</Typography>
        <PostsPreview
          posts={mapArrToPosts([
            'Why The «Pomodoro Habit» Is The Key To Many Other Habits',
            // 'How I Have Powered Up My Pomodoro Routine With Home Automation System',
            'How Pomodoro Muscled Me Up (For Real!)',
            //'How Pomodoro Increased My Productivity',
            //'How I Have Changed Pomodoro Technique Under Myself',
            'How I Was Applying Pomodoro Technique Wrong For the Whole Freaking Year',
            //'Why My Pomodoro Routine Sucks and How To Make It Better',
            '25 Things That Improved In My Life By Using The Pomodoro Technique',
            'Cool Thing About Pomodoro I Want To Try' /* offline button */,
            'My Research — The Science Behind Pomodoro Technique',
            'How Pomodoro Is Connected to Discipline' /* Easy to understand hard to execute */,
            //'Why Broken Pomodoro Is Better Than No Pomodoro' /* It still improves life */,
            //'What Modern Desease Can Be Cured By Pomodoro' /* I Believe Into Putting Limits */,
            //'Pomodoro — It Is Not Only About Better Process' /* I know where the end is */,
            //'Unwrapping Pomodoro Technique For Me, Personally' /* Putting limits, plan ahead, focus on one, know the end etc */,
            'How To Survive The Pomodoro Break?' /* about restoring context */,
          ])}
        />
        <br />
        <Typography
          variant={'headline'}
          style={{
            color: red[500],
          }}
          gutterBottom
        >
          DON'T MISS THE GOOD STUFF. SUBSCRIBE!
        </Typography>
        <SubscriptionForm />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { released: { eq: true } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            projects
            cover {
              publicURL
              childImageSharp {
                fixed(width: 240, height: 120) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
